@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

html,
body {
    height: 100%;
}

body[data-env='DEV']:before {
    background-color: #418bca;
    color: #fff;
}

body[data-env='ACP']:before {
    background-color: #ffce35;
    color: #fff;
}

body[data-env='PRD']:before {
    background-color: #cf0a0a;
    color: #fff;
}

app-root {
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

c-content {
    min-height: 68vh;
}

.panel {
    margin-bottom: 15px;
    box-shadow: none;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid transparent;
}

.panel-default {
    border-color: #dadada;
}

.panel-body {
    padding: 30px 40px;
}

.table {
    border-spacing: 0;
    border-collapse: collapse;
    color: #041e42;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    word-break: normal;
}

.table>thead>tr>th {
    vertical-align: bottom;
}

.table tr.active td {
    background-color: #d9d9d9 !important;
    color: black;
}

.table-responsive {
    overflow-x: 100%;
}

.table-sm {
    font-size: smaller;
}

.table-clickable>tbody>tr {
    cursor: pointer;
}

.app td,
.app th {
    font-size: 1.4rem;
}

th {
    text-align: left;
}

.dark,
section.dark,
.dark>section,
section:nth-child(odd).dark {
    background-color: #f7f7f7;
}

.app c-content>section,
c-content.app>section {
    background-color: #f7f7f7;
    padding: 30px 0;
}

.form-control.filter {
    color: #747472;
    height: 46px;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    font-size: inherit;
    font-weight: normal;
    background-color: #f5f5f5;
    border: 1px solid transparent;
    padding: 4px 12px;
}

input[placeholder],
textarea[placeholder] {
    color: inherit;
}

.pull-right {
    float: right !important;
}

.btn-toolbar {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: -5px;
}

.btn-icon {
    font-size: 2rem;
    margin-right: 0px;
}

.btn-action {
    margin-left: 5px;
}

.badge {
    margin-left: 5px;
}

.badge-default {
    padding: 1px 9px 2px;
    font-size: 12.025px;
    font-weight: bold;
    white-space: nowrap;
    color: #033a6a;
    background-color: #dee2e6;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    width: 50px;
}

.form-control.ng-touched.ng-invalid {
    border: 2px solid #a94442;
}

.form-control.text-bold {
    font-weight: bold;
}

svg:not(:root).svg-inline--fa {
    margin-right: 5px;
}

.img-under-construction {
    margin-top: -73px;
    margin-left: 42%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    width: 300px;
}

.b-0 {
    border: none !important;
}

.br-0 {
    border-right: none !important;
}

.bl-0 {
    border-left: none !important;
}

/* ------------------ Swal Components  ---------------------- */

.swal2-container {
    z-index: 999999 !important;
}

.control-label.required:after {
    color: #d00;
    content: "*";
    position: absolute;
    margin-left: 5px;
}

::ng-deep .bs-datepicker-head {
    background-color: #041e42 !important;
}
